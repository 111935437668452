<template>
  <div :id="type"
       class="circularChart">
    <!-- {{type}} -->
  </div>
</template>
<script>
//  
export default {
  props: {
    type: {
      default: 'c1'
    },
    berthOccupy: {
      type: Object,
      default: function () {
        return {}
      }
    },
    memberCarNumber: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      berthList: [{ name: '空闲泊位', value: [0] }, { name: '占用泊位', value: [0] }],
      memberCarNumberList: [{ name: '会员', value: [0] }, { name: '非会员', value: [0] }],
      tipTitle: ''
    }
  },
  watch: {
    berthOccupy: {
      handler (value) {
        this.berthList = [];
        this.berthList.push({ name: '空闲泊位', value: [value.freeNumber] })
        this.berthList.push({ name: '占用泊位', value: [value.occupyNumber] })
        this.drawGraph();
      },
      deep: true
    },
    memberCarNumber: {
      handler (value) {
        this.memberCarNumberList = [];
        this.memberCarNumberList.push({ name: '会员', value: [value.membarCarNumber] })
        this.memberCarNumberList.push({ name: '非会员', value: [value.nonmemberCarNumber] })
        this.drawGraph();
      },
      deep: true
    }
  },
  methods: {
    drawGraph () {
      let that = this;
      let circularChart = this.$echarts.init(document.getElementById(this.type));
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: "{b}<br/>数量: {c}(个) <br/> 占比: {d}%"
        },
        legend: {
          orient: "horizontal",
          bottom: 1,
          data: that.type == "c3" ? ['空闲泊位', '占用泊位'] : ['会员', '非会员']
        },
        series: [
          {
            name: this.tipTitle,
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center"
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "30",
                  fontWeight: "bold"
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: that.type == "c3" ? that.berthList : that.memberCarNumberList
          }
        ]
      };
      circularChart.setOption(option, true);
    }
  },
  mounted () {
    this.drawGraph();
  },
  created () {
    if (this.type == "c3") {
      this.tipTitle = "泊位占用率"
    } else if (this.type == "c4") {
      this.tipTitle = "在场会员车占比"
    }
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circularChart
  width 100%
  height 300px
  overflow hidden
</style>
